






























































































import { Component, Vue } from 'vue-property-decorator';
import {
  Office,
  OfficeExternalLinkType,
  WebsiteLevel,
  Testimonial,
  Profile,
} from 'client-website-ts-library/types';
import {
  API,
  Config,
  Logger,
  LogLevel,
} from 'client-website-ts-library/services';

import { ItemCyclerImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import TestimonialList from '../Testimonials/TestimonialList.vue';
import TestimonialCycler from '../Cycler/TestimonialCycler.vue';
import PhotoRow from '../Layout/PhotoRow.vue';

@Component({
  components: {
    TestimonialList,
    TestimonialCycler,
    PhotoRow,
  },
})
export default class Footer extends Vue {
  private office: Office | null = null;

  private profile: Profile | null = null;

  private filter: TestimonialFilter = new TestimonialFilter();

  private testimonials: Testimonial[] | null = null;

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/1',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/2',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/5',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/4',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
  ];

  mounted() {
    this.loadOffice();
    this.loadProfile();

    API.Testimonials.Search(this.filter).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }

  loadProfile(): void {
    API.Profiles.Get('d6aca385-25ec-41b9-a486-ee7435227951').then((profile) => {
      this.profile = profile;
    });
  }

  loadOffice(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  get facebookLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Facebook
    )?.Url;
  }

  get youtubeLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.YouTube
    )?.Url;
  }

  get twitterLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Twitter
    )?.Url;
  }

  get googleMyBusinessLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.GoogleMyBusiness
    )?.Url;
  }

  get linkedinLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.LinkedIn
    )?.Url;
  }

  get pinterestLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Pinterest
    )?.Url;
  }

  get instagramLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Instagram
    )?.Url;
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }
}
